import ReactCountdown from 'react-countdown'
import Grid from '@mui/material/Grid'

import CustomBox from './CustomBox'

interface CountdownProps {
  date: Date | string | number
}

export default function Countdown({ date }: CountdownProps) {
  return (
    <ReactCountdown
      date={date}
      renderer={({ days, hours, minutes, seconds, completed }: any) => {
        if (completed) {
          return null
        }

        return (
          <Grid justifyContent='center' spacing={2} container>
            <Grid item>
              <CustomBox value={days} label='jours' />
            </Grid>
            <Grid item>
              <CustomBox value={hours} label='heures' />
            </Grid>
            <Grid item>
              <CustomBox value={minutes} label='minutes' />
            </Grid>
            <Grid item>
              <CustomBox value={seconds} label='secondes' />
            </Grid>
          </Grid>
        )
      }}
    />
  )
}
