import FullscreenSection from '../Components/FullscreenSection'
import Title from '../Components/Title'
import Countdown from '../Components/Countdown'

export default function Root() {
  const randomNumber = Math.floor(Math.random() * 2) + 1

  return (
    <FullscreenSection
      url={`${process.env.PUBLIC_URL}/img/bg${randomNumber}.jpg`}
    >
      <Title title='Nous arrivons bientôt!' />
      <Countdown date={new Date('July 30, 2022 00:01:00')} />
    </FullscreenSection>
  )
}
