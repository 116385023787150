import styled from '@mui/material/styles/styled'
import Container from '@mui/material/Container'

const BackgroundImage = styled('div')(
  {
    position: 'relative',
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  ({ url }: FullscreenSectionProps) => ({
    '&::before': {
      content: '""',
      backgroundImage: `url(${url})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: '0.75',
    },
  }),
)

interface FullscreenSectionProps {
  url: string
  children: React.ReactNode
}

export default function FullscreenSection({
  url,
  children,
}: FullscreenSectionProps) {
  return (
    <BackgroundImage url={url}>
      <Container style={{ position: 'relative' }}>{children}</Container>
    </BackgroundImage>
  )
}
