import Typography from '@mui/material/Typography'

interface TitleProps {
  title: string
}

export default function Title({ title }: TitleProps) {
  return (
    <Typography variant='h2' align='center' gutterBottom>
      {title}
    </Typography>
  )
}
