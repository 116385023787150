import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

interface CustomBoxProps {
  value: string
  label: string
}

export default function CustomBox({ value, label }: CustomBoxProps) {
  return (
    <Paper elevation={3}>
      <Box
        sx={{
          width: 70,
          height: 70,
        }}
      >
        <Grid
          alignContent='center'
          alignItems='center'
          direction='column'
          container
        >
          <Grid item>
            <Typography variant='h4'>{value}</Typography>
          </Grid>
          <Grid item>
            <Typography variant='caption'>{label}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
